* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  background-color: #222;
}

.webgl {
  position: absolute;
  top: 0;
  left: 0;
  outline: none;
}

.eisa {
  width: 100vw;
  max-height: 100vh;
  padding-top: 620px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 2rem;
}
section {
  width: 100vw;
  height: auto;
  padding: 50px;
  padding-bottom: 100px;
  color: #fff;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
